import React from "react";
import "./HealthOptimizationsTable.css"; // Import your CSS if needed

const HealthOptimizationsTable = ({ usageLogs }) => {  
  // Helper function to assign status classes
  const getStatusClass = (status) => {
    switch (status) {
      case "completed":
        return "status-completed";
      case "expiring soon":
        return "status-expiring";
      case "pending":
        return "status-pending";
      case "in progress":
        return "status-in-progress";
      default:
        return "";
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="table-primary">
          <tr>
            <th>Product Name</th>
            <th>Date</th>
            <th style={{ width: "150px" }}>Status</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {usageLogs.length > 0 ? (
            usageLogs.map((log, index) => (
              <tr key={index}>
                <td>{log.product_name || 'N/A'}</td>
                <td>{log.date ? new Date(log.date).toLocaleDateString() : 'N/A'}</td>
                <td>
                  <span className={`status-pill ${getStatusClass(log.status)}`}>
                    {log.status}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No logs available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default HealthOptimizationsTable;
