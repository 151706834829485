import { useForm } from 'react-hook-form';

export const EmailLoginForm = ({ onSubmit, onBack, error }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='login-form'>
      <div className='form-group fw-bold'>
        <label htmlFor='email'>Enter your email</label>
        <input
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
          })}
          type='email'
          className='form-control'
          placeholder='email'
        />
        {errors.email && (
          <span className='text-danger small'>{errors.email.message}</span>
        )}
      </div>

      <div className='form-group mt-3 fw-bold'>
        <label htmlFor='password'>Enter your password</label>
        <input
          {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters',
            },
          })}
          type='password'
          className='form-control'
          placeholder='password'
        />
        {errors.password && (
          <span className='text-danger small'>{errors.password.message}</span>
        )}
      </div>

      {error && <p className='text-danger small'>{error}</p>}

      <button type='submit' className='btn btn-dark w-100 mt-3'>
        Login
      </button>
      <button
        type='button'
        className='btn btn-link w-100 mt-2 text-decoration-none'
        onClick={onBack}
      >
        Back
      </button>
    </form>
  );
};
