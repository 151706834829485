import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { useAuth } from './auth/useAuth';

const PackagePage = ({ funnel }) => {
  const [packageDetails, setPackageDetails] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('annual');
  const { handleStripeCheckout } = useAuth();
  const navigate = useNavigate();

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        const response = await api.get(
          `/packages/${funnel.suggested_package}/`
        );
        setPackageDetails(response.data);
      } catch (error) {
        console.error('Failed to fetch package details:', error);
      }
    };
    fetchPackageDetails();
  }, []);

  const handleCheckout = async () => {
    try {
      // Store selected package and plan details
      localStorage.setItem('selectedPackageId', funnel.suggested_package);
      localStorage.setItem('selectedSubscription', selectedPlan);

      // Check if user has a valid token
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        // Set the token in the API headers
        api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        try {
          // Verify token validity
          await api.get('/clients/profile/');

          // Call handleStripeCheckout directly since user is authenticated
          handleStripeCheckout(accessToken, funnel.suggested_package);
        } catch (authError) {
          // Check if the error is a 401 Unauthorized
          if (authError.response && authError.response.status === 401) {
            // Token is invalid or expired
            localStorage.removeItem('accessToken');
            api.defaults.headers.common['Authorization'] = null;
            navigate('/login', { replace: true });
          } else {
            console.error('An unexpected error occurred:', authError);
          }
        }
      } else {
        // If user is not authenticated, redirect to login page
        navigate('/login', { replace: true });
      }
    } catch (error) {
      // Handle any unforeseen errors in the outer try block
      console.error('An error occurred during checkout:', error);
      // Optionally, display a generic error message to the user
      // e.g., toast.error('Checkout failed. Please try again.');
    }
  };

  if (!packageDetails) {
    return <div className='p-6'>Loading...</div>;
  }

  return (
    <div>
      <h2 className='text-2xl font-semibold mb-4'>{packageDetails.name}</h2>
      {packageDetails.photo && (
        <img
          src={packageDetails.photo}
          alt={packageDetails.name}
          className='w-full h-64 object-cover rounded-lg mb-4'
        />
      )}
      <h3 className='text-xl font-semibold mb-2'>Included Products:</h3>
      <ul className='list-disc list-inside mb-4'>
        {packageDetails.package_products.map((product) => (
          <li key={product.id} className='text-lg'>
            {product.product.name}
          </li>
        ))}
      </ul>
      <div className='mt-6'>
        <p className='text-lg font-semibold mb-4'>Select your plan</p>
        <div className='flex flex-col space-y-4 w-full'>
          <button
            className={`px-6 py-2 rounded-full w-full flex justify-between items-center ${
              selectedPlan === 'annual'
                ? 'bg-blue-500 text-white'
                : 'bg-blue-200 text-blue-500'
            }`}
            onClick={() => handlePlanSelect('annual')}
          >
            <span>Annual Subscription</span>
            <span>${packageDetails.annual_price}/year</span>
          </button>
          <button
            className={`px-6 py-2 rounded-full w-full flex justify-between items-center ${
              selectedPlan === 'monthly'
                ? 'bg-blue-500 text-white'
                : 'bg-blue-200 text-blue-500'
            }`}
            onClick={() => handlePlanSelect('monthly')}
          >
            <span>Monthly Subscription</span>
            <span>${packageDetails.monthly_price}/month</span>
          </button>
        </div>
        <div className='flex justify-center w-full mt-6 mb-8'>
          <button
            className='px-6 py-2 bg-blue-400 text-white rounded-full w-1/2 flex justify-center items-center mb-8'
            onClick={handleCheckout}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackagePage;
