// hooks/useAuth.js
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { signInWithGoogle } from '../../authUtils';
import api from 'services/api';

export const useAuth = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleStripeCheckout = async (accessToken, selectedPackageId) => {
    try {
      const response = await api.post(
        '/create_checkout/',
        {
          type: 'package',
          ids: [String(selectedPackageId)],
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId: response.data.id });
    } catch (error) {
      throw new Error('Failed to initiate payment process');
    }
  };

  const handleAuthSuccess = async (
    accessToken,
    hasSubscription,
    selectedPackageId
  ) => {
    localStorage.setItem('accessToken', accessToken);

    if (selectedPackageId) {
      await handleStripeCheckout(accessToken, selectedPackageId);
    } else {
      navigate('/subscriptions');
    }
  };

  const loginWithGoogle = async (storedVariables) => {
    setIsLoading(true);
    setError(null);

    try {
      const result = await signInWithGoogle();
      const idToken = await result.user.getIdToken();

      const response = await api.post('/firebase-login/', {
        token: idToken,
        storedVariables,
      });

      await handleAuthSuccess(
        response.data.accessToken,
        response.data.has_subscription,
        response.data.selectedPackageId
      );
    } catch (error) {
      setError('Failed to login with Google. Please try again.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithEmail = async (email, password, storedVariables) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.post('/email-login/', {
        email,
        password,
        storedVariables,
      });

      if (response.status !== 200) {
        throw new Error('Invalid credentials');
      }

      await handleAuthSuccess(
        response.data.accessToken,
        response.data.has_subscription,
        response.data.selectedPackageId
      );
    } catch (error) {
      setError('Invalid email or password.');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleStripeCheckout,
    loginWithGoogle,
    loginWithEmail,
    isLoading,
    error,
  };
};
