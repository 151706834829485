import React, { useState } from 'react';
import { parseLocalStorage } from './utils';
import { useAuth } from './useAuth';
import { EmailLoginForm } from './EmailLoginForm';
import SignUpPage from './SignUpPage';
import './LoginPage.css';

const LoginPage = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const { loginWithGoogle, loginWithEmail, isLoading, error } = useAuth();

  const handleGoogleLogin = async () => {
    try {
      const storedVariables = parseLocalStorage();
      await loginWithGoogle(storedVariables);
    } catch (error) {
      // Error is handled by useAuth hook
    }
  };

  const handleEmailLogin = async (formData) => {
    try {
      const storedVariables = parseLocalStorage();
      await loginWithEmail(formData.email, formData.password, storedVariables);
    } catch (error) {
      // Error is handled by useAuth hook
    }
  };

  if (showSignUp) {
    return <SignUpPage />;
  }

  return (
    <div className='login-container'>
      <div className='login-left'>
        <img
          src='/running_group.png'
          alt='Fitness'
          className='background-image'
        />
      </div>
      <div className='login-right'>
        <h1 className='brand-name'>HEALTHITRACK</h1>
        <h2 className='welcome-text'>Welcome back</h2>

        {isLoading && <div className='loader'>Loading...</div>}

        {!isEmailLogin ? (
          <div className='login-buttons'>
            <button
              className='login-button google'
              onClick={handleGoogleLogin}
              disabled={isLoading}
            >
              <img src='/google.png' alt='Google logo' />
              Log in with Google
            </button>
            <button
              className='login-button email'
              onClick={() => setIsEmailLogin(true)}
              disabled={isLoading}
            >
              <img src='/email.png' alt='Email logo' />
              Log in with Email
            </button>
          </div>
        ) : (
          <EmailLoginForm
            onSubmit={handleEmailLogin}
            onBack={() => setIsEmailLogin(false)}
            error={error}
          />
        )}

        <p className='signup-link'>
          First time here?{' '}
          <button
            className='text-primary border-0 bg-transparent p-0'
            onClick={() => setShowSignUp(true)}
          >
            Create an account
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
