import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = ({ showProgressBar, progressBar }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const isRootPage = location.pathname === "/";

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsAuthenticated(true);
      // Fetch user profile (replace this with your actual API call)
      const fetchUserProfile = async () => {
        try {
          const response = await fetch("/api/user/profile", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          setUserProfile(data);
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      };
      fetchUserProfile();
    }
  }, []);

  // If on the root page or progress bar should be shown, render a simple header
  if (showProgressBar || isRootPage) {
    return (
      <header className="flex py-4 z-20 relative">
        <div className="flex w-full items-end">
          <div className="w-1/4">
            <Link to="/" className="no-underline">
              <h1 className="text-2xl font-semibold text-selected">
                HEALTHITRACK
              </h1>
            </Link>
          </div>
          <div className="w-1/2 pb-2">
            <div
              className="transition-opacity duration-1500"
              style={{ opacity: 1 }}
            >
              {progressBar}
            </div>
          </div>
          <div className="w-1/4"></div>
        </div>
      </header>
    );
  } else {
    return (
      <header className="bg-white shadow">
        <div className="flex items-center justify-between py-4 px-6">
          {/* Left Section: Logo */}
          <div className="flex items-center space-x-4">
            <Link to="/" className="no-underline">
              <h1 className="text-2xl font-semibold text-selected">
                HEALTHITRACK
              </h1>
            </Link>

            {/* Desktop Navigation Links (hidden on mobile) */}
            {isAuthenticated && (
              <div className="hidden md:flex space-x-8">
                <Link
                  to="/dashboard"
                  className="text-gray-700 hover:text-blue-500 no-underline"
                >
                  Health Dashboard
                </Link>
                <Link
                  to="/subscriptions"
                  className="text-gray-700 hover:text-blue-500 no-underline"
                >
                  Subscriptions
                </Link>
                <Link
                  to="/billing"
                  className="text-gray-700 hover:text-blue-500 no-underline"
                >
                  Billing
                </Link>
              </div>
            )}
          </div>

          {/* Right Section: User Actions + Hamburger */}
          <div className="flex items-center space-x-4">
            {isAuthenticated ? (
              <>
                {/* User Profile */}
                <img
                  src={`https://i.pravatar.cc/150?u=2`} // Random avatar
                  alt="User Profile"
                  className="h-8 w-8 rounded-full border border-gray-300"
                />
              </>
            ) : (
              <Link
                to="/login"
                className="text-gray-700 hover:text-blue-500 hidden md:block"
              >
                Login
              </Link>
            )}

            {/* Hamburger Menu (Mobile) */}
            <button
              className="block md:hidden text-gray-700 hover:text-blue-500 focus:outline-none"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              {menuOpen ? (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu Dropdown */}
        {menuOpen && (
          <div className="md:hidden px-6 pb-4">
            {isAuthenticated ? (
              <div className="flex flex-col space-y-2">
                <Link
                  to="/dashboard"
                  className="text-gray-700 hover:text-blue-500 no-underline block"
                  onClick={() => setMenuOpen(false)}
                >
                  Health Dashboard
                </Link>
                <Link
                  to="/subscriptions"
                  className="text-gray-700 hover:text-blue-500 no-underline block"
                  onClick={() => setMenuOpen(false)}
                >
                  Subscriptions
                </Link>
                <Link
                  to="/billing"
                  className="text-gray-700 hover:text-blue-500 no-underline block"
                  onClick={() => setMenuOpen(false)}
                >
                  Billing
                </Link>
                {/* Notifications Link on Mobile */}
                <button className="relative text-gray-700 hover:text-blue-500 text-left">
                  <span className="absolute top-0 right-0 bg-red-500 rounded-full h-2 w-2"></span>
                  <span>Notifications</span>
                </button>
              </div>
            ) : (
              <Link
                to="/login"
                className="text-gray-700 hover:text-blue-500 no-underline block"
                onClick={() => setMenuOpen(false)}
              >
                Login
              </Link>
            )}
          </div>
        )}
      </header>
    );
  }
};

export default Header;
