import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import HealthOptimizationsTable from './HealthTable';

const ThankYouModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Thank You for Your Purchase!</h2>
        <p>We appreciate your interest.</p>
        <button onClick={onClose}>Close</button>
      </div>
      <style jsx>{`
        .modal-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .modal-content {
          background: white;
          padding: 2rem;
          border-radius: 8px;
          text-align: center;
          max-width: 500px;
          width: 90%;
        }
        button {
          margin-top: 1rem;
          padding: 0.5rem 1rem;
          background: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        button:hover {
          background: #0056b3;
        }
      `}</style>
    </div>
  );
};

const Subscriptions = () => {
  const [profile, setProfile] = useState(null);
  const [usageLogs, setUsageLogs] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  // Check for payment_hash in the URL and show the thank you popup
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentHash = urlParams.get('payment_hash');

    if (paymentHash) {
      setShowThankYou(true);

      // Remove payment_hash from the URL to prevent the popup from showing again
      urlParams.delete('payment_hash');
      const newUrl =
        window.location.pathname +
        (urlParams.toString() ? `?${urlParams.toString()}` : '');
      window.history.replaceState({}, document.title, newUrl);
    }
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const fetchUserProfile = async () => {
      try {
        const profile_resp = await api.get(`/clients/profile/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setProfile(profile_resp.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        if (error.response && error.response.status === 401) {
          setUnauthorized(true);
        }
      }
    };

    fetchUserProfile();
  }, []);

  // Transformation Function to process usage logs directly from the API
  const transformUsageLogs = (logs) => {
  return logs
    .filter((log) => log.subscription_id && log.product_name) // Filter invalid logs
    .map((log) => ({
      subscription_id: log.subscription_id,
      product_id: log.product_id,
      product_name: log.product_name,
      status: log.status || 'pending', // Default to 'pending' if 'status' is missing
      date: log.date,
    }));
};


  // Fetch usage logs when profile is available
  useEffect(() => {
    if (profile && profile.id) {
      const accessToken = localStorage.getItem('accessToken');

      const fetchUsageLogs = async () => {
        try {
          const usageLogsResp = await api.get(
            `/usage-logs/products-usage/?client_id=${profile.id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          const transformedLogs = transformUsageLogs(usageLogsResp.data);
          setUsageLogs(transformedLogs);
        } catch (error) {
          console.error('Error fetching usage logs:', error);
        }
      };

      fetchUsageLogs();
    }
  }, [profile]);

  if (unauthorized) {
    window.location.href = '/login';
  }

  // Extract plan details from profile
  const planDetails = profile?.packages_data || [];

  return (
    <div className='container py-4'>
      {/* Thank You Modal */}
      <ThankYouModal show={showThankYou} onClose={() => setShowThankYou(false)} />

      {/* Title Section */}
      <h3 className='mb-4'>Your current plans</h3>

      {/* Loop through each package and display details */}
      {planDetails.length > 0 ? (
        planDetails.map((plan, index) => (
          <div key={index}>
            {/* Top row: image on the left, plan details on the right */}
            <div className='row align-items-start'>
              <div className='col-12 col-md-4'>
                <img
                  src={plan.photo}
                  alt={plan.name}
                  className='img-fluid rounded mb-3'
                />
              </div>
              <div className='col-12 col-md-8'>
                <h4 className='mb-3'>{plan.name}</h4>
                <div dangerouslySetInnerHTML={{ __html: plan.description }} />
              </div>
            </div>

            {/* Divider */}
            <hr style={{ border: '1px solid #ccc', margin: '2rem 0' }} />
          </div>
        ))
      ) : (
        <p>No packages found.</p>
      )}

      {/* Table Section: Health Optimizations */}
      {planDetails.length > 0 ? (
        planDetails.map((plan, index) => (
          <div key={index}>
            {/* Show product_name and its usage logs */}
            <h4>{plan.name}</h4>
            <HealthOptimizationsTable
              usageLogs={usageLogs.filter(
                (log) => log.subscription_id === plan.subscription_id
              )} // Filter usage logs by subscription_id
            />
          </div>
        ))
      ) : (
        <p>No usage logs found.</p>
      )}
    </div>
  );
};

export default Subscriptions;
