import api from '../../services/api';
import React, { useEffect, useState } from 'react';

const Billing = () => {
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        // Await the API call to ensure response is resolved
        const response = await api.get('/billing/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });

        // Assuming 'api' is using axios
        if (response.status !== 200) {
          throw new Error(response.data.error || 'Failed to fetch payments.');
        }

        // Set payments assuming the data structure is { data: [...] }
        setPayments(response.data.data);
      } catch (err) {
        // Handle different error structures
        setError(err.response?.data?.error || err.message || 'An unexpected error occurred.');
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ color: 'red' }}>Error: {error}</div>;

  return (
    <div>
      <h2>Your Past Payments</h2>
      {payments.length === 0 ? (
        <p>No payments found.</p>
      ) : (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Date</th>
              <th style={tableHeaderStyle}>Amount</th>
              <th style={tableHeaderStyle}>Status</th>
              <th style={tableHeaderStyle}>Product</th>
              <th style={tableHeaderStyle}>Subscription Duration</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id}>
                <td style={tableCellStyle}>
                  {new Date(payment.created * 1000).toLocaleDateString()}
                </td>
                <td style={tableCellStyle}>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: payment.currency.toUpperCase(),
                  }).format(payment.amount / 100)}
                </td>
                <td style={tableCellStyle}>{capitalizeFirstLetter(payment.status)}</td>
                <td style={tableCellStyle}>{payment.product_ids || 'N/A'}</td>
                <td style={tableCellStyle}>{capitalizeFirstLetter(payment.subscription_duration) || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

// Helper function to capitalize first letter
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Basic styles for table
const tableHeaderStyle = {
  borderBottom: '2px solid #ddd',
  textAlign: 'left',
  padding: '8px',
};

const tableCellStyle = {
  borderBottom: '1px solid #ddd',
  textAlign: 'left',
  padding: '8px',
};

export default Billing;
