export const styles = {
  icon: {
    marginRight: '8px',
    width: '24px',
    height: '24px',
  },
  tooltipIcon: {
    marginLeft: '8px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
  },
  popupOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    position: 'relative',
    maxWidth: '500px',
    width: '90%',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '1.5rem',
    cursor: 'pointer',
  },
  rangeBar: {
    position: 'relative',
    height: '8px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
  },
  activeRange: {
    position: 'absolute',
    height: '100%',
    backgroundColor: '#007bff',
    left: '0%',
    width: '100%',
    borderRadius: '4px',
  },
  chartContainer: {
    width: '100%',
    height: '200px',
  },
};
