import { styles } from './styles';

/**
 * Helper function to render minMax data dynamically
 */
export const renderMinMax = (minMax, unit) => {
  if (minMax.low && minMax.high) {
    // Low and High Range
    return (
      <div className='min-max-section mb-3'>
        <div className='d-flex justify-content-between'>
          <span className='text-muted'>Low</span>
          <span className='text-muted'>High</span>
        </div>
        <div style={styles.rangeBar}>
          <div style={styles.activeRange}></div>
        </div>
        <div className='d-flex justify-content-between mt-1'>
          <span className='text-muted'>
            {minMax.low} {unit}
          </span>
          <span className='text-muted'>
            {minMax.high} {unit}
          </span>
        </div>
      </div>
    );
  }

  // Normal Range
  if (minMax.normal) {
    return (
      <div className='min-max-section mb-3 text-center'>
        <p className='text-muted'>
          Normal: {minMax.normal} {unit}
        </p>
      </div>
    );
  }

  // Age-Group Specific Ranges
  return (
    <div className='min-max-section mb-3'>
      {Object.entries(minMax)
        .reduce((acc, [ageGroup, range], index) => {
          if (index % 2 === 0) {
            // Start a new row every two items
            acc.push([]);
          }
          acc[acc.length - 1].push({ ageGroup, range });
          return acc;
        }, [])
        .map((row, rowIndex) => (
          <div key={rowIndex} className='d-flex mb-2'>
            {row.map(({ ageGroup, range }) => (
              <div key={ageGroup} className='me-4 fs-6'>
                <span
                  className='text-muted fw-bold'
                  style={{ fontSize: '0.75rem' }}
                >
                  {ageGroup}:
                </span>
                <span
                  className='text-muted ms-2'
                  style={{ fontSize: '0.75rem' }}
                >
                  {range}
                </span>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export const getBordersForTitle = (title) => {
  const borderMapping = {
    A1c: [
      { label: 'Low', value: 4.0 },
      { label: 'Good', value: 4.5 },
      { label: 'Fair', value: 4.8 },
      { label: 'High', value: 5.6 },
    ],
    'Fasting Insulin': [
      { label: 'Low', value: 2.0 },
      { label: 'Good', value: 4.0 },
      { label: 'Fair', value: 6.0 },
      { label: 'High', value: 8.0 },
    ],
    Estradiol: [
      { label: 'Low', value: 10 },
      { label: 'Good', value: 40 },
      { label: 'High', value: 100 },
    ],
    Glucose: [
      { label: 'Low', value: 70 },
      { label: 'Good', value: 99 },
      { label: 'Fair', value: 125 },
      { label: 'High', value: 150 },
    ],
    'Total Cholesterol': [
      { label: 'Desirable', value: 200 },
      { label: 'Borderline High', value: 240 },
      { label: 'High', value: 270 },
    ],
    Hdl: [
      { label: 'Low', value: 40 },
      { label: 'Good', value: 60 },
      { label: 'Optimal', value: 80 },
    ],
    Ldl: [
      { label: 'Optimal', value: 100 },
      { label: 'Near Optimal', value: 130 },
      { label: 'Borderline High', value: 160 },
      { label: 'High', value: 190 },
      { label: 'Very High', value: 220 },
    ],
    Triglycerides: [
      { label: 'Normal', value: 150 },
      { label: 'Borderline High', value: 200 },
      { label: 'High', value: 500 },
      { label: 'Very High', value: 600 },
    ],
    'Total Testosterone': [
      { label: 'Low', value: 300 },
      { label: 'Good', value: 800 },
      { label: 'High', value: 900 },
    ],
    'Free Testosterone': [
      { label: 'Low', value: 5 },
      { label: 'Good', value: 15 },
      { label: 'Fair', value: 25 },
      { label: 'High', value: 30 },
    ],
    'Second Estradiol': [
      { label: 'Low', value: 15 },
      { label: 'Good', value: 60 },
      { label: 'Fair', value: 100 },
      { label: 'High', value: 120 },
    ],
    'Dhea S': [
      { label: 'Low', value: 100 },
      { label: 'Good', value: 200 },
      { label: 'High', value: 300 },
    ],
  };
  return borderMapping[title] || [];
};

export const getHoverColor = (value, borders) => {
  if (!borders || borders.length === 0) return 'grey';

  for (let i = 0; i < borders.length - 1; i++) {
    if (value < borders[i + 1].value) {
      switch (borders[i].label) {
        case 'Low':
          return 'yellow';
        case 'Optimal':
        case 'Desirable':
        case 'Near Optimal':
        case 'Normal':
        case 'Good':
          return 'green';
        case 'Borderline High':
        case 'Fair':
          return 'orange';
        case 'Very High':
        case 'High':
          return 'red';
        default:
          return 'grey';
      }
    }
  }
  return 'red';
};

export const getQualityLabel = (value, borders) => {
  if (!borders || borders.length === 0 || value === null) return null;

  for (let i = 0; i < borders.length - 1; i++) {
    if (value < borders[i + 1].value) {
      return borders[i].label;
    }
  }

  return borders[borders.length - 1].label;
};
