export const parseLocalStorage = () => {
  const storedData = { ...localStorage };
  const parsedData = {};
  for (const [key, value] of Object.entries(storedData)) {
    try {
      parsedData[key] = JSON.parse(value);
    } catch {
      parsedData[key] = value;
    }
  }
  return parsedData;
};
